import { graphql } from "gatsby";
import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import styled from "styled-components";

import ImagesGallery from "../components/ImagesGallery";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Title from "../components/Title";

const Gallery = ({ data }) => {
  const {
    allAirtable: { nodes: photos },
  } = data;

  return (
    <Layout>
      <Wrapper className="section">
        <Title
          title="Galeria"
          subtitle="Zapoznaj się ze zdjęciami naszego gabinetu"
        />
        {photos && <ImagesGallery images={photos} />}
      </Wrapper>
    </Layout>
  );
};

export const Head = () => {
  return <Seo title="Galeria - Animal" />;
};

const Wrapper = styled.div`
  min-height: 80vh;
`;

export const query = graphql`
  query {
    allAirtable(
      sort: { fields: data___name, order: ASC }
      filter: { table: { eq: "Gallery" } }
    ) {
      nodes {
        id
        data {
          name
          photo {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 90)
              }
            }
          }
        }
      }
    }
  }
`;

export default Gallery;
