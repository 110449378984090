import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import styled from "styled-components";

import { useEffect } from "react";
import { queries } from "../shared/layout";

const ImagesGallery = ({ images }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedPhotoId, setSelectedPhotoId] = useState(0);
  const [isPreviousPhotoBtnVisible, setIsPreviousPhotoBtnVisible] =
    useState(false);
  const [isNextPhotoBtnVisible, setIsNextPhotoBtnVisible] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const selectedPhotoIndexInArray = images.findIndex(
      image => image.id === selectedPhotoId
    );
    if (!(selectedPhotoIndexInArray > 0)) {
      setIsPreviousPhotoBtnVisible(false);
      setIsNextPhotoBtnVisible(true);
    } else if (selectedPhotoIndexInArray === images.length - 1) {
      setIsPreviousPhotoBtnVisible(true);
      setIsNextPhotoBtnVisible(false);
    } else {
      setIsPreviousPhotoBtnVisible(true);
      setIsNextPhotoBtnVisible(true);
    }
  }, [selectedPhoto]);

  const handlePopupClick = ({ target }) => {
    if (target !== ref.current) {
      return;
    }
    setSelectedPhoto(null);
  };

  const onSelectPrevious = () => {
    const selectedPhotoIndexInArray = images.findIndex(
      image => image.id === selectedPhotoId
    );
    const previousPhoto = images[selectedPhotoIndexInArray - 1];
    const { id } = previousPhoto;
    const { photo: image } = previousPhoto.data;
    setSelectedPhoto(getImage(image.localFiles[0]));
    setSelectedPhotoId(id);
  };

  const onSelectNext = () => {
    const selectedPhotoIndexInArray = images.findIndex(
      image => image.id === selectedPhotoId
    );
    const previousPhoto = images[selectedPhotoIndexInArray + 1];
    const { id } = previousPhoto;
    const { photo: image } = previousPhoto.data;
    setSelectedPhoto(getImage(image.localFiles[0]));
    setSelectedPhotoId(id);
  };

  return (
    <Wrapper>
      <div className="gallery">
        {images.map(photo => {
          const { id } = photo;
          const { name, photo: image } = photo.data;
          return (
            <div
              className="photo"
              key={id}
              onClick={() => {
                setSelectedPhotoId(id);
                setSelectedPhoto(getImage(image.localFiles[0]));
              }}
            >
              <GatsbyImage image={getImage(image.localFiles[0])} alt={name} />
            </div>
          );
        })}
      </div>
      {selectedPhoto && (
        <div className="lightbox" ref={ref} onClick={handlePopupClick}>
          <div className="photo-box">
            <button onClick={() => setSelectedPhoto(null)}>
              <MdClose />
            </button>
            <div className="photo">
              {isPreviousPhotoBtnVisible && (
                <button
                  className="arrow-btn arrow-btn-left"
                  onClick={onSelectPrevious}
                >
                  <MdKeyboardArrowLeft />
                </button>
              )}
              <GatsbyImage image={selectedPhoto} alt="zdjecie gabinetu" />
              {isNextPhotoBtnVisible && (
                <button
                  className="arrow-btn arrow-btn-right"
                  onClick={onSelectNext}
                >
                  <MdKeyboardArrowRight />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;

    ${queries.tablet} {
      grid-template-columns: repeat(3, 1fr);
    }

    & > .photo {
      border: 0.5rem solid transparent;
      height: 100%;
      width: auto;
      box-sizing: border-box;

      &:hover {
        border: 0.5rem solid ${({ theme }) => theme.colors.primary};
        border-radius: 1rem;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }

  .lightbox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.grayTransparent};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;

    .photo-box {
      background-color: ${({ theme }) => theme.colors.white};
      padding: 1rem;
      border-radius: 1rem;

      display: flex;
      flex-direction: column;
      position: relative;

      .arrow-btn {
        position: absolute;
        top: 50%;
        z-index: 100;
        border-radius: 1rem;
        background-color: ${({ theme }) => theme.colors.whiteTransparent};
        height: 5rem;
        width: 7rem;
      }

      .arrow-btn-left {
        left: 2rem;
      }

      .arrow-btn-right {
        right: 2rem;
      }

      button {
        width: 3rem;
        height: 3rem;
        justify-self: end;
        background-color: transparent;
        border: none;
        margin-left: auto;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
          color: ${({ theme }) => theme.colors.primaryDark};
        }
      }
    }
  }
`;

export default ImagesGallery;
